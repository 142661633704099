<template>
  <g>
    <g id="g6104" clip-path="url(#clipPath6108)"
      ><g id="g6110" transform="rotate(180,1393.25,5918.25)"
        ><path
          d="M 75.5,0 C 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 c 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6112"/></g
      ><g id="g6114" transform="rotate(180,4266.25,2316.25)"
        ><path
          d="M 154.5,0 C 154.5,85.328 85.328,154.5 0,154.5 -85.328,154.5 -154.5,85.328 -154.5,0 c 0,-85.328 69.172,-154.5 154.5,-154.5 85.328,0 154.5,69.172 154.5,154.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6116"/></g
      ><g id="g6118" transform="rotate(180,1166.25,5113.25)"
        ><path
          d="M 154.5,0 C 154.5,85.328 85.328,154.5 0,154.5 -85.328,154.5 -154.5,85.328 -154.5,0 c 0,-85.328 69.172,-154.5 154.5,-154.5 85.328,0 154.5,69.172 154.5,154.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6120"/></g
      ><g id="g6122" transform="rotate(180,1393.25,5653.75)"
        ><path
          d="M 75.5,0 C 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 c 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6124"/></g
      ><g id="g6126" transform="rotate(180,979.25,2722.25)"
        ><path
          d="M 75.5,0 C 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 c 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6128"/></g
      ><g id="g6130" transform="rotate(180,979.25,3176.25)"
        ><path
          d="M 75.5,0 C 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 c 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6132"/></g
      ><g id="g6134" transform="rotate(180,2716.25,891.25)"
        ><path
          d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5 -62.68432,113.5 -113.5,62.68432 -113.5,0 c 0,-62.68432 50.81568,-113.5 113.5,-113.5 62.68432,0 113.5,50.81568 113.5,113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6136"/></g
      ><path
        d="m 8608,1782.5 c 0,41.6975 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.8025 -75.5,-75.5 0,-41.6975 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.8025 75.5,75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6138"/><path
        d="m 2408,1782.5 c 0,41.6975 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.8025 -75.5,-75.5 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6140"/><path
        d="m 8589,1782.5 c 0,31.2041 -25.2959,56.5 -56.5,56.5 -31.2041,0 -56.5,-25.2959 -56.5,-56.5 0,-31.2041 25.2959,-56.5 56.5,-56.5 31.2041,0 56.5,25.2959 56.5,56.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6142"/><path
        d="m 2389,1782.5 c 0,31.2041 -25.2959,56.5 -56.5,56.5 -31.2041,0 -56.5,-25.2959 -56.5,-56.5 0,-31.2041 25.2959,-56.5 56.5,-56.5 31.2041,0 56.5,25.2959 56.5,56.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6144"/><path
        d="m 3902,1988.5 c 0,83.6711 -67.8289,151.5 -151.5,151.5 -83.6711,0 -151.5,-67.8289 -151.5,-151.5 0,-83.6711 67.8289,-151.5 151.5,-151.5 83.6711,0 151.5,67.8289 151.5,151.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6146"/><path
        d="m 3832,1988.5 c 0,45.0112 -36.4888,81.5 -81.5,81.5 -45.0112,0 -81.5,-36.4888 -81.5,-81.5 0,-45.0112 36.4888,-81.5 81.5,-81.5 45.0112,0 81.5,36.4888 81.5,81.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6148"/><path
        d="m 7266,1988.5 c 0,83.6711 -67.8286,151.5 -151.5,151.5 -83.6714,0 -151.5,-67.8289 -151.5,-151.5 0,-83.6711 67.8286,-151.5 151.5,-151.5 83.6714,0 151.5,67.8289 151.5,151.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6150"/><path
        d="m 7196,1988.5 c 0,45.0112 -36.4888,81.5 -81.5,81.5 -45.0112,0 -81.5,-36.4888 -81.5,-81.5 0,-45.0112 36.4888,-81.5 81.5,-81.5 45.0112,0 81.5,36.4888 81.5,81.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6152"/><g id="g6154" transform="rotate(180,4266.25,3554.25)"
        ><path
          d="M 75.5,0 C 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 c 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6156"/></g
      ><g id="g6158" transform="rotate(180,1166.25,3554.25)"
        ><path
          d="M 75.5,0 C 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 c 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6160"/></g
      ><path
        d="m 8693,11581.5 c 0,88.642 -71.8584,160.5 -160.5,160.5 -88.6416,0 -160.5,-71.858 -160.5,-160.5 0,-88.642 71.8584,-160.5 160.5,-160.5 88.6416,0 160.5,71.858 160.5,160.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6162"/><path
        d="m 8608,11581.5 c 0,41.697 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.803 -75.5,-75.5 0,-41.697 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.803 75.5,75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6164"/><path
        d="m 2493,11581.5 c 0,88.642 -71.8584,160.5 -160.5,160.5 -88.6416,0 -160.5,-71.858 -160.5,-160.5 0,-88.642 71.8584,-160.5 160.5,-160.5 88.6416,0 160.5,71.858 160.5,160.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6166"/><path
        d="m 2408,11581.5 c 0,41.697 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.803 -75.5,-75.5 0,-41.697 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.803 75.5,75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6168"/><path
        d="m 8693,3265.5 c 0,88.6416 -71.8584,160.5 -160.5,160.5 -88.6416,0 -160.5,-71.8584 -160.5,-160.5 0,-88.6416 71.8584,-160.5 160.5,-160.5 88.6416,0 160.5,71.8584 160.5,160.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6170"/><path
        d="m 8608,3265.5 c 0,41.6975 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.8025 -75.5,-75.5 0,-41.6975 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.8025 75.5,75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6172"/><path
        d="m 2493,3265.5 c 0,88.6416 -71.8584,160.5 -160.5,160.5 -88.6416,0 -160.5,-71.8584 -160.5,-160.5 0,-88.6416 71.8584,-160.5 160.5,-160.5 88.6416,0 160.5,71.8584 160.5,160.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6174"/><path
        d="m 2408,3265.5 c 0,41.6975 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.8025 -75.5,-75.5 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6176"/><path
        d="M 2143,12517 H 8721"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6178"/><path
        d="m 1638,12630 -44,-76 -131,-226"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6180"/><path
        d="m 9401,12328 -131,226 -44,76 H 1638"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6182"/><g id="g6184" transform="rotate(180,4606.25,4841.25)"
        ><path
          d="M 151.5,0 C 151.5,83.67114 83.67114,151.5 0,151.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6186"/></g
      ><path
        d="m 9023,12176 h 38 V 9682"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6188"/><g id="g6190" transform="rotate(180,4511.75,6182.75)"
        ><path
          d="M 188.5,0 C 188.5,104.10568 104.10568,188.5 0,188.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6192"/></g
      ><path
        d="m 8834,12403 v -38"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6194"/><g id="g6196" transform="rotate(180,4360.75,6201.75)"
        ><path
          d="m -113.5,0 c 0,-62.68432 50.81568,-113.5 113.5,-113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6198"/></g
      ><g id="g6200" transform="rotate(180,1071.75,6201.75)"
        ><path
          d="m 0,-113.5 c 62.68432,0 113.5,50.81568 113.5,113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path6202"/></g
      ><path
        d="m 2030,12365 v 38"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6204"/><path
        d="m 1841.5,12177 c 104.1057,0 188.5,84.395 188.5,188.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6206"/><path
        d="m 1803,9682 v 2494 h 38"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6208"/><path
        d="m 1652.5,9531 c 83.6711,0 151.5,67.8291 151.5,151.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6210"/><path
        d="m 9212,9530 h 189 V 2232 H 1463 v 7298 h 189"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6212"/><path
        d="M 1463,1400 H 9401"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6214"/><path
        d="m 9401,9530 v 2646"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6216"/><path
        d="m 9401,1400 v 832"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6218"/><path
        d="m 9401,12176 v 152"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6220"/><path
        d="M 1463,12328 V 12176 9530"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6222"/><path
        d="m 1463,1400 v 832"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6224"/><path
        d="M 9270,12554 H 1594"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6226"/><path
        d="M 9401,12176 H 9061"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6228"/><path
        d="m 1463,12176 h 340"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path6230"/></g
  ></g>
</template>
<script>
export default {
  name: "PressureMa11Bottom",
};
</script>
